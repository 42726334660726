import React from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Image from "reusecore/src/elements/Image";
import Input from "reusecore/src/elements/Input";
import Container from "../../../components/UI/Container";

import ContactFromWrapper from "./contact.style";

import VendorLogos from "../../../assets/image/logo.svg";

const TrialSection = ({
  sectionWrapper,
  row,
  title,
  description,
  col,
  textArea,
  imageArea,
  ImageOne,
  note,
  button,
  contactForm
}) => {
  return (
    <Box {...sectionWrapper} id="accessSection">
      <Container>
        <Box {...row}>
          <Box {...imageArea}>
            <Image {...ImageOne} src={VendorLogos} alt="VendorLogos" />
          </Box>
          <Box {...textArea}>
            <Heading {...title} content="Request your access!" />
            <Text
              {...description}
              content="We are actively working to make this service available to everyone. But before we do that, we're testing it with a very small number of managers."
            />
          </Box>
          <Box {...row}>
            <ContactFromWrapper
              name="request_access"
              method="post"
              data-netlify="true"
              action="/success"
              data-netlify-honeypot="bot-field"
              netlify
            >
              <Input
                inputType="email"
                placeholder="Your email address"
                iconPosition="right"
                isMaterial={false}
                className="email_input"
              />
              <Button {...button} title="Request Access" />
            </ContactFromWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TrialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object
};

TrialSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    className: "trial-section",
    pt: ["20px", "40px", "60px", "80px"],
    pb: ["0px", "0px", "0px", "80px"]
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center"
  },
  textArea: {
    width: ["100%", "100%", "80%", "43%"]
  },
  imageArea: {
    width: ["100%", "100%", "43%"],
    mb: ["35px", "35px", "40px", "40px"]
  },
  title: {
    fontSize: ["32px", "32px", "36px", "42px", "48px"],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "28px",
    textAlign: "center",
    lineHeight: "1.25"
  },
  description: {
    fontSize: ["15px", "15px", "16px", "16px", "16px"],
    color: "textColor",
    lineHeight: "2.1",
    textAlign: "center",
    mb: ["35px", "35px", "40px", "60px"]
  },
  ImageOne: {
    ml: "auto",
    mr: "auto"
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    colors: "primaryWithBg"
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#0f2137"
  }
};

export default TrialSection;
