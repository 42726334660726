import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { saasTheme } from "common/src/theme/saas";
import { ResetCSS } from "common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper
} from "common/src/containers/Saas/saas.style";
import Navbar from "common/src/containers/Saas/Navbar";
import PartnerSection from "common/src/containers/Saas/PartnerSection";
import VisitorSection from "common/src/containers/Saas/VisitorSection";
import ServiceSection from "common/src/containers/Saas/ServiceSection";
import Footer from "common/src/containers/Saas/Footer";
import TrialSection from "common/src/containers/Saas/TrialSection";
import TimelineSection from "common/src/containers/Saas/TimelineSection";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import SEO from "../components/seo";

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="A platform for software engineering managers" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <PartnerSection />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
